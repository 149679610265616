import React, { useState } from 'react';

import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import { ConversionType, trackConversion } from 'utils/analytics';
import { StudentLoanBookAppointmentResult } from 'enums/StudentLoanForgivenessFlowResults';
import CalendlyModal from 'components/Card/StudentLoanForgiveness/Modal/CalendlyModal';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import AreYouSureModal from 'components/Card/StudentLoanForgiveness/Modal/AreYouSureModal';

import { ReactComponent as Contract } from 'images/contract.svg';

import styles from './BookAppointment.module.scss';

const BookAppointment = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const [isScheduleOpen, setScheduleIsOpen] = useState(false);
  const [areYouSureModalOpen, setAreYouSureModalOpen] = useState(false);

  const handleSchedule = () => {
    analytics.track('Schedule Consultation Link Opened');
    setAreYouSureModalOpen(false);
    setScheduleIsOpen(true);
  };

  const onSchedule = () => {
    trackConversion(ConversionType.BookedStudentLoanMeeting);
    setScheduleIsOpen(false);
    handleNext(StudentLoanBookAppointmentResult.Schedule);
  };

  return (
    <>
      <CalendlyModal open={isScheduleOpen} onClose={() => setScheduleIsOpen(false)} onSchedule={onSchedule} />
      {areYouSureModalOpen && (
        <AreYouSureModal
          onClose={() => setAreYouSureModalOpen(false)}
          onNext={handleSchedule}
          onNotNow={() => handleNext(StudentLoanBookAppointmentResult.NotNow)}
          nextLabel="Schedule Appointment"
        />
      )}
      <FormNavigation {...navigationInfo} />
      <FormContainer icon={<Contract />} title="Let's apply together" subtitle="We do the work for you.">
        <p className={styles.label}>Talk to us and see your savings in just 15 minutes.</p>

        <Button className={styles.button} onClick={handleSchedule}>
          Schedule Appointment
        </Button>

        <Button type={ButtonType.Inverted} onClick={() => setAreYouSureModalOpen(true)}>
          Not Now
        </Button>
      </FormContainer>
    </>
  );
};

export default BookAppointment;
